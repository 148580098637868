<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="id"
      >
        <a-input
          v-decorator="['id',{ initialValue:'0' }]"
          name="id"></a-input>
      </a-form-item>

      <a-form-item
        label="标题"
      >
        <a-input
          v-decorator="[
            'title',
            {rules: [{ required: true, message: '请输入标题' }]}
          ]"
          name="title"
          placeholder="请输入标题"/>
      </a-form-item>

      <a-form-item
        :maxLength="500"
        label="内容"
      >
        <a-textarea
          :rows="10"
          v-decorator="[
            'content',
            {rules: [{ required: true, message: '请输入内容' ,max:500}]}
          ]"
          name="content"
          placeholder="请输入内容"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        form: this.$form.createForm(this)
      }
    }
  }
</script>
